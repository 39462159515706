import React from "react";
import Layout from "../components/shared/layout";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-react-intl";
const Style = styled.div`
  width: 80%;
  margin: auto;

  h1 {
    font-size: 56px;
    font-weight: 300;
    @media (max-width: 400px) {
      font-size: 48px;
    }
  }
  h2 {
    font-size: 42px;
    font-weight: 300;
    @media (max-width: 400px) {
      font-size: 32px;
    }
  }
  p,
  li {
    font-size: 18px;
    line-height: 34px;
    font-weight: 300;
    @media (max-width: 400px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
  .content {
    margin-bottom: 48px;
    a {
      color: #2c6491;
      text-decoration: none;
    }
  }
`;
function Installation() {
  const intl = useIntl();
  return (
    <Layout>
      <Style>
        <div class="wrapper">
          <header>
            <h1>
              {intl.formatMessage({
                id: "InstallationThanks",
              })}
            </h1>
            <h2>
              {intl.formatMessage({
                id: "InstallationSuccessfullyInstalled",
              })}
            </h2>
          </header>
          <div class="content">
            <p>
              {intl.formatMessage({
                id: "InstallationUl",
              })}
            </p>
            <ul>
              <li>
                {intl.formatMessage({
                  id: "InstallationLi1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "InstallationLi2",
                })}
                <a href="https://pdfpro10.zendesk.com/categories/20051126-pdf-pro-10-knowledge-base">
                  {intl.formatMessage({
                    id: "InstallationLi2Link",
                  })}
                </a>
              </li>
              <li>
                {intl.formatMessage({
                  id: "InstallationLi3",
                })}
                <a href="https://pdfpro10.zendesk.com/anonymous_requests/new">
                  {intl.formatMessage({
                    id: "InstallationLi3Link",
                  })}
                </a>{" "}
                {intl.formatMessage({
                  id: "InstallationLi3PostLink",
                })}
              </li>
            </ul>
          </div>
        </div>
      </Style>
    </Layout>
  );
}

export default Installation;
